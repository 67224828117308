import StreetGlideAdaptiveLedHeadlamp from '../../assets/images/CVO/street-glide/hotspots/adaptive-led-headlamp.jpg';
import LimitedAdaptiveLedHeadlamp from '../../assets/images/CVO/limited/hotspots/adaptive-led-headlamp.jpg';

import StreetGlideAudioRockfordFosgate from '../../assets/images/CVO/street-glide/hotspots/audio-rockford-fosgate.jpg';
import RoadGlideAudioRockfordFosgate from '../../assets/images/CVO/road-glide/hotspots/audio-rockford-fosgate.jpg';
import TriGlideAudioRockfordFosgate from '../../assets/images/CVO/tri-glide/hotspots/audio-rockford-fosgate.jpg';
import LimitedAudioRockfordFosgate from '../../assets/images/CVO/limited/hotspots/audio-rockford-fosgate.jpg';
import StreetGlideSpecialAudioRockfordFosgate from '../../assets/images/touring/street-glide-special/hotspots/audio-rockford-fosgate.jpg';
import RoadGlideSpecialAudioRockfordFosgate from '../../assets/images/touring/road-glide-special/hotspots/audio-rockford-fosgate.jpg';

import TriGlideBoomStageSpeakers from '../../assets/images/CVO/tri-glide/hotspots/boom-stage-speakers.jpg';
import LimitedBoomStageSpeakers from '../../assets/images/CVO/limited/hotspots/boom-stage-speakers.jpg';

import RoadGlideExclusiveComboWheels from '../../assets/images/CVO/road-glide/hotspots/exclusive-combo-wheels.jpg';

import HammockSeat from '../../assets/images/hotspots/hammock-seat.jpg';
import BrakingLights from '../../assets/images/hotspots/braking-lights.jpg';

import StreetGlideHeavyBreather from '../../assets/images/CVO/street-glide/hotspots/heavy-breather.jpg';
import RoadGlideHeavyBreather from '../../assets/images/CVO/road-glide/hotspots/heavy-breather.jpg';

import StreetGlideKahunaCollection from '../../assets/images/CVO/street-glide/hotspots/kahuna-collection.jpg';
import RoadGlideKahunaCollection from '../../assets/images/CVO/road-glide/hotspots/kahuna-collection.jpg';
import TriGlideKahunaCollection from '../../assets/images/CVO/tri-glide/hotspots/kahuna-collection.jpg';
import LimitedKahunaCollection from '../../assets/images/CVO/limited/hotspots/kahuna-collection.jpg';

import StreetGlideMilwaukeeEight from '../../assets/images/CVO/street-glide/hotspots/milwaukee-eight.jpg';
import RoadGlideMilwaukeeEight from '../../assets/images/CVO/road-glide/hotspots/milwaukee-eight.jpg';

import TriGlideTomahawkWheels from '../../assets/images/CVO/tri-glide/hotspots/tomahawk-wheels.jpg';
import LimitedTomahawkWheels from '../../assets/images/CVO/limited/hotspots/tomahawk-wheels.jpg';

import TriGlideTwinMilwaukeeEight from '../../assets/images/CVO/tri-glide/hotspots/twin-milwaukee-eight.jpg';
import LimitedTwinMilwaukeeEight from '../../assets/images/CVO/limited/hotspots/twin-milwaukee-eight.jpg';

import StreetGlideSpecialConsoleFuelFiller from '../../assets/images/touring/street-glide-special/hotspots/console-fuel-filler.jpg';
import RoadGlideSpecialConsoleFuelFiller from '../../assets/images/touring/road-glide-special/hotspots/console-fuel-filler.jpg';

import StreetGlideSpecialDaymakerLedHeadlamp from '../../assets/images/touring/street-glide-special/hotspots/daymaker-led-headlamp.jpg';
import RoadKingSpecialDaymakerLedHeadlamp from '../../assets/images/touring/road-king-special/hotspots/daymaker-led-headlamp.jpg';

import StreetGlideSpecialEngineGuard from '../../assets/images/touring/street-glide-special/hotspots/engine-guard.jpg';
import RoadGlideSpecialEngineGuard from '../../assets/images/touring/road-glide-special/hotspots/engine-guard.jpg';
import RoadKingSpecialEngineGuard from '../../assets/images/touring/road-king-special/hotspots/engine-guard.jpg';

import StreetGlideSpecialVentilatorAirCleaner from '../../assets/images/touring/street-glide-special/hotspots/ventilator-air-cleaner.jpg';
import RoadGlideSpecialVentilatorAirCleaner from '../../assets/images/touring/road-glide-special/hotspots/ventilator-air-cleaner.jpg';
import RoadKingSpecialVentilatorAirCleaner from '../../assets/images/touring/road-king-special/hotspots/ventilator-air-cleaner.jpg';

export const adaptiveLedHeadlamp = {
  details: {
    title: 'Daymaker Adaptive LED Headlamp',
    description:
      'The most-advanced headlamp system ever developed for a Harley-Davidson® motorcycle. Patented sensors and electronics are integrated into the headlamp and activate based on the lean of the bike, to project light into corners.',
  },
  zoomImages: {
    streetGlide: StreetGlideAdaptiveLedHeadlamp,
    limited: LimitedAdaptiveLedHeadlamp,
  },
};

export const audioRockfordFosgate = {
  details: {
    title: 'Harley-Davidson® Audio powered by Rockford Fosgate',
    description:
      'A four-speaker Stage II system, powered by a single 400-watt amplifier, built and tuned specifically for riding.',
  },
  zoomImages: {
    streetGlide: StreetGlideAudioRockfordFosgate,
    roadGlide: RoadGlideAudioRockfordFosgate,
    triGlide: TriGlideAudioRockfordFosgate,
    limited: LimitedAudioRockfordFosgate,
    streetGlideSpecial: StreetGlideSpecialAudioRockfordFosgate,
    roadGlideSpecial: RoadGlideSpecialAudioRockfordFosgate,
  },
};

export const boomStageSpeakers = {
  details: {
    title: 'Boom! Stage I 2-way Speakers',
    description:
      'Four Boom!™ Stage I 2-way speakers (located in fairing and Tour-Pak®), powered by a single, 4-channel, 300-watt RMS amplifier.',
  },
  zoomImages: {
    triGlide: TriGlideBoomStageSpeakers,
    limited: LimitedBoomStageSpeakers,
  },
};

export const exclusiveComboWheels = {
  details: {
    title: 'Exclusive 21”/18” Combo Wheels',
    description:
      'This new wheel design is exclusive to CVO™ and features a cast-aluminum rim with laced spokes that evokes a hot-rod pedigree and one-off custom vibe. The design of this wheel enables TPMS (tire pressure monitoring systems) and is the only 21-inch front wheel offered on a H-D Touring model.',
  },
  zoomImages: {
    roadGlide: RoadGlideExclusiveComboWheels,
  },
};

export const hammockSeat = {
  details: {
    title: 'Harley® Hammock Heated & Cooled Seat',
    description:
      'Extend and optimize your riding season thanks to cooling and heating technology built into this premium seat for Touring and Trike models.',
  },
  zoomImages: {
    all: HammockSeat,
  },
};

export const heavyBreather = {
  details: {
    title: 'Screamin’ Eagle® Heavy Breather',
    description: 'Big, bold hot-rod style intake for improved air flow and performance.',
  },
  zoomImages: {
    streetGlide: StreetGlideHeavyBreather,
    roadGlide: RoadGlideHeavyBreather,
  },
};

export const milwaukeeEight = {
  details: {
    title: 'Milwaukee Eight® 117 Engine',
    description:
      'Exclusive to the 2021 CVO™ models, and includes the Screamin’ Eagle® Heavy Breather intake.',
  },
  zoomImages: {
    streetGlide: StreetGlideMilwaukeeEight,
    roadGlide: RoadGlideMilwaukeeEight,
  },
};

export const tomahawkWheels = {
  details: {
    title: 'Tomahawk 19”/18” Wheels',
    description:
      'Available in either Gloss Black with Contrast Satin, or Gloss Black with Contrast Chrome.',
  },
  zoomImages: {
    triGlide: TriGlideTomahawkWheels,
    limited: LimitedTomahawkWheels,
  },
};

export const twinMilwaukeeEight = {
  details: {
    title: 'Twin-Cooled™ Milwaukee-Eight® 117 Engine',
    description:
      'Exclusive to the 2021 CVO™ models, and includes the Screamin’ Eagle® Heavy Breather intake.',
  },
  zoomImages: {
    triGlide: TriGlideTwinMilwaukeeEight,
    limited: LimitedTwinMilwaukeeEight,
  },
};

export const kahunaCollection = {
  details: {
    title: 'Kahuna Collection',
    description:
      'A matched selection of accessories: heated hand grips, shifter pegs, brake pedal cover, rider and passenger footboards, and muffler end caps.',
  },
  zoomImages: {
    streetGlide: StreetGlideKahunaCollection,
    roadGlide: RoadGlideKahunaCollection,
    triGlide: TriGlideKahunaCollection,
    limited: LimitedKahunaCollection,
  },
};

export const brakingLights = {
  details: {
    title: 'LED Turn, Running, Braking Lights',
    description:
      'Signature LED Rear Lighting delivers modern style and function that improves rider conspicuity to surrounding traffic. The light pipe around the perimeter diffuses light and is always illuminated as a running light for eye-catching visibility.',
  },
  zoomImages: {
    all: BrakingLights,
  },
};

export const consoleFuelFiller = {
  details: {
    title: 'Low profile center console & side fuel filler',
    description:
      'The Street Glide® Special features a new low-profile center console with side fuel filler.',
  },
  zoomImages: {
    streetGlideSpecial: StreetGlideSpecialConsoleFuelFiller,
    roadGlideSpecial: RoadGlideSpecialConsoleFuelFiller,
  },
};

export const daymakerLedHeadlamp = {
  details: {
    title: 'Daymaker LED Headlamp',
    description:
      'The most-advanced headlamp system ever developed for a Harley-Davidson® motorcycle-Patented sensors and electronics integrated into the headlamp that feature. 12 additional LED lights that activate based on the lean of the bike to project light into corners to illuminate areas of the road that may be unlit by traditional LED headlamps.',
  },
  zoomImages: {
    streetGlideSpecial: StreetGlideSpecialDaymakerLedHeadlamp,
    roadKingSpecial: RoadKingSpecialDaymakerLedHeadlamp,
  },
};

export const engineGuard = {
  details: {
    title: 'Low Profile Engine Guard',
    description:
      'A new low-profile engine guard for the Road Glide® Special adds custom style streamlining the bike’s profile and differentiating it from the other H-D Touring models.',
  },
  zoomImages: {
    streetGlideSpecial: StreetGlideSpecialEngineGuard,
    roadGlideSpecial: RoadGlideSpecialEngineGuard,
    roadKingSpecial: RoadKingSpecialEngineGuard,
  },
};

export const ventilatorAirCleaner = {
  details: {
    title: 'High Performance Ventilator Air Cleaner',
    description:
      'All Road Glide® Special models are equipped with the high-performance Ventilator air cleaner for enhanced air flow to the engine.',
  },
  zoomImages: {
    streetGlideSpecial: StreetGlideSpecialVentilatorAirCleaner,
    roadGlideSpecial: RoadGlideSpecialVentilatorAirCleaner,
    roadKingSpecial: RoadKingSpecialVentilatorAirCleaner,
  },
};
