import type { Hotspot } from '../../types/global';
import LargeEngineZoom from '../../assets/images/fat-boy-114/zoomed-images/large-engine-zoom.png';
import LEDTurnSignalZoom from '../../assets/images/fat-boy-114/zoomed-images/led-turn-signal-zoom.png';
import LegendaryIconZoom from '../../assets/images/fat-boy-114/zoomed-images/legendary-icon-zoom.png';

export const fatBoy114Hotspots: Array<Hotspot> = [
  {
    coordinates: {
      mobile: {
        xPos: '6%',
        yPos: '42%',
      },
      desktop: {
        xPos: '2%',
        yPos: '70%',
      },
    },
    details: {
      title: 'LED Turn Signal Inserts',
      description:
        'Signature style and modern functionality that improves rider conspicuity to surrounding traffic. The light pipe outer “signature” ring adds visibility and unique styling, while brilliant center LED elements flash with an eye-catching signal.',
      coordinates: {
        xPos: '-16%',
        yPos: '-97%',
      },
      size: 'medium',
    },
    zoomImage: {
      src: LEDTurnSignalZoom,
      alt: 'LED Turn Signal Inserts',
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '13%',
        yPos: '68.5%',
      },
      desktop: {
        xPos: '15%',
        yPos: '72%',
      },
    },
    details: {
      title: 'New Bright Chrome',
      description:
        'For 2021, the rear fender struts, tank console, and ventilator air cleaner cover are finished in brilliant chrome.',
      size: 'medium',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '25%',
        yPos: '34%',
      },
      desktop: {
        xPos: '16%',
        yPos: '48%',
      },
    },
    details: {
      title: 'High Performance Rear Mono Shock',
      description:
        'Rear mono shock is mounted in a manner that preserves the hardtail look with pre-load adjustment located below the seat, keeping the lines clean and making it easy to dial in the ride and personalize for rider weight.',
      size: 'large',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '42%',
        yPos: '51%',
      },
      desktop: {
        xPos: '68%',
        yPos: '46%',
      },
    },
    details: {
      title: 'Large Engine Displacement Upgrades',
      description:
        'Increase the displacement of your Milwaukee-Eight® engine without removing the engine from the chassis. This Stage III kit has been developed for a solid response you can feel, every time you roll on the throttle.',
      coordinates: {
        xPos: '-142%',
        yPos: '0',
      },
      size: 'medium',
    },
    zoomImage: {
      src: LargeEngineZoom,
      alt: 'Large Engine Displacement Upgrades',
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '76.5%',
        yPos: '21%',
      },
      desktop: {
        xPos: '47%',
        yPos: '23%',
      },
    },
    details: {
      title: 'LED Headlamp',
      description:
        'The signature headlamp is mounted in a bold nacelle designed to complement the forks and further define the Fat Boy® styling.',
      size: 'medium',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '53.5%',
        yPos: '70%',
      },
      desktop: {
        xPos: '33%',
        yPos: '62%',
      },
    },
    details: {
      title: 'Milwaukee-Eight® 114 Engine',
      description:
        'The Milwaukee-Eight® 114 V-Twin engine is the most-powerful available for the Softail®, delivering inspiring performance and classic Harley-Davidson emotion.',
      size: 'medium',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '95%',
        yPos: '65%',
      },
      desktop: {
        xPos: '49%',
        yPos: '76%',
      },
    },
    details: {
      title: 'Lakester Wheels',
      description:
        'Solid aluminum Lakester wheels (18-inch front and rear) with massive Michelin® Scorcher® 11 160mm front and 240mm rear tires, the widest tires offered on a Harley-Davidson® model, give the Fat Boy® model its steamroller presence.',
      size: 'medium',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '58.5%',
        yPos: '32.5%',
      },
      desktop: {
        xPos: '34%',
        yPos: '15%',
      },
    },
    details: {
      title: 'Legendary Icon',
      description:
        'Timeless never goes out of style. The Fat Boy® tank badge, an original Willie G. Davidson design, debuted with the first model year bike in 1990 and has remained a staple ever since.',
      coordinates: {
        xPos: '0',
        yPos: '104%',
      },
      size: 'medium',
    },
    zoomImage: {
      src: LegendaryIconZoom,
      alt: 'Legendary Icon',
    },
  },
];
