import { isBefore } from 'date-fns';
import { getCurrentTime } from './getCurrentTime';
import { siteLaunchStartTime } from '../data/dates';
import { Phases } from '../data/enum/phases';

export const getCurrentPhase = (): Phases => {
  const currentTime = getCurrentTime();

  if (isBefore(currentTime, siteLaunchStartTime)) {
    return Phases.COUNTDOWN;
  }

  return Phases.LAUNCH;
};
