/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */

export enum Sections {
  REGISTRATION = 'registration',
  RIDER_STORIES = 'rider-stories',
  CVO = 'cvo',
  SPECIALS = 'specials',
  STREET_BOB_114 = 'street-bob-114',
  FAT_BOY_114 = 'fat-boy-114',
  WHATS_TO_COME = 'whats-to-come',
  LIVE_EVENT = 'live-event',
  SESSION_GALLERY = 'session-gallery',

  // PANAM
  PANAM_OVERVIEW = 'panam-overview',
  RIDING_GEAR = 'riding-gear',
  EQUIPPED_FOR_ADVENTURE = 'equipped-for-adventure',
}
