import type { Hotspot, Sketch, Image, Coordinates } from '../types/global';
import { fatBoy114Hotspots } from './hotspots/fatBoy114';
import FatBoy114BikeImage from '../assets/images/fat-boy-114/desktop/bike.png';
import FatBoy114BikeImageMobile from '../assets/images/fat-boy-114/mobile/fat-boy-mobile.png';
import FatBoy114BikeImageHover from '../assets/images/fat-boy-114/desktop/bike_hover.png';
import FatBoy114BikeImageDark from '../assets/images/fat-boy-114/desktop/bike_dark.png';
import backgroundImageLightBeam from '../assets/images/fat-boy-114/desktop/lights/1-leds-light-beam-compressed.png';
import backgroundImageHazeBlue from '../assets/images/fat-boy-114/desktop/lights/2-light-haze-blue-compressed.png';
import backgroundImageHazeWhite from '../assets/images/fat-boy-114/desktop/lights/3-light-haze-white-compressed.png';

// Sketch assets
import FatBoy114 from '../assets/images/fat-boy-114/sketch/sketch-fat-boy.png';
import FatBoy114Colored from '../assets/images/fat-boy-114/sketch/hover-fat-boy.png';

interface FatBoy114Data {
  title: string;
  eyebrow: string;
  description: string;
  backgroundText: string;
  bikeImage: {
    bright: string;
    brightMobile: string;
    dark: string;
    hover: string;
    lights: {
      lightBeam: Image;
      hazeBlue: Image;
      hazeWhite: Image;
    };
  };
  hotspots: Array<Hotspot>;
  bikeShopButtonPosition: {
    mobile: Coordinates;
    desktop: Coordinates;
  };
  sketch: Sketch;
  signature: string;
}

export const fatBoy114Data: FatBoy114Data = {
  title: 'IT’S TIME TO SHINE',
  eyebrow: 'FAT BOY® 114',
  description:
    'The classic Fat Boy icon you know. Now back with the chrome finish you love. Combine it with a Milwaukee-Eight® 114 engine and the line up’s fattest tires and 2021’s starting to look a little brighter.',
  backgroundText: 'Fat Boy 114',
  bikeImage: {
    dark: FatBoy114BikeImageDark,
    hover: FatBoy114BikeImageHover,
    bright: FatBoy114BikeImage,
    brightMobile: FatBoy114BikeImageMobile,
    lights: {
      hazeBlue: {
        src: backgroundImageHazeBlue,
        alt: 'Fat Boy 114',
      },
      hazeWhite: {
        src: backgroundImageHazeWhite,
        alt: 'Fat Boy 114',
      },
      lightBeam: {
        src: backgroundImageLightBeam,
        alt: 'Fat Boy 114',
      },
    },
  },
  hotspots: fatBoy114Hotspots,
  bikeShopButtonPosition: {
    mobile: {
      xPos: '64%',
      yPos: '24%',
    },
    desktop: {
      xPos: '71%',
      yPos: '30%',
    },
  },
  sketch: {
    initial: {
      src: FatBoy114,
      alt: 'Fat Boy 114',
    },
    hover: {
      src: FatBoy114Colored,
      alt: 'Fat Boy 114',
    },
  },
  signature: 'Fat Boy 114',
};
