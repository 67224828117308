export const links = {
  hdwebsite: 'https://H-D.com',
  privacy: 'https://www.harley-davidson.com/locale/footer/utility/privacy-policy.html',
  registration:
    'https://www.harley-davidson.com/us/en/products/bikes/future-vehicles/pan-america.html',
  testUrl: 'https://live-test.harley-davidson.com',
  termsOfUse: 'https://www.harley-davidson.com/us/en/footer/utility/terms-of-use.html',
  logoutSSO: 'https://qa.h-dnet.com/system/sling/logout.html',
  contactYourDealer: 'https://www.harley-davidson.com/locale/tools/find-a-dealer.html',
  findDealer: 'https://form.harley-davidson.com',
  api: {
    login: '/api/sp/request',
    download: {
      dealers: '/api/docs/dealers',
      public: '/api/docs/public',
    },
  },
};
