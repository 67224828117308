/* eslint-disable max-lines */
import type { Hotspot } from '../../types/global';
import TriGlideAllLedLightning from '../../assets/images/CVO/tri-glide/hotspots/all-led-lightning.jpg';

import {
  adaptiveLedHeadlamp,
  audioRockfordFosgate,
  boomStageSpeakers,
  exclusiveComboWheels,
  hammockSeat,
  heavyBreather,
  kahunaCollection,
  milwaukeeEight,
  tomahawkWheels,
  twinMilwaukeeEight,
} from './global';

export const streetGlideHotspots: Array<Hotspot> = [
  // handle
  {
    coordinates: {
      mobile: {
        xPos: '68%',
        yPos: '32%',
      },
      desktop: {
        xPos: '68%',
        yPos: '30%',
      },
    },
    details: kahunaCollection.details,
    zoomImage: {
      src: kahunaCollection.zoomImages.streetGlide,
      alt: kahunaCollection.details.title,
    },
  },
  // engine
  // TODO: adjust position, design was missing either this or the one for heavy breather
  {
    coordinates: {
      mobile: {
        xPos: '55%',
        yPos: '70%',
      },
      desktop: {
        xPos: '55%',
        yPos: '70%',
      },
    },
    details: milwaukeeEight.details,
    zoomImage: {
      src: milwaukeeEight.zoomImages.streetGlide,
      alt: milwaukeeEight.details.title,
    },
  },
  // speaker
  {
    coordinates: {
      mobile: {
        xPos: '23%',
        yPos: '62%',
      },
      desktop: {
        xPos: '24%',
        yPos: '56%',
      },
    },
    details: audioRockfordFosgate.details,
    zoomImage: {
      src: audioRockfordFosgate.zoomImages.streetGlide,
      alt: audioRockfordFosgate.details.title,
    },
  },
  // front light
  {
    coordinates: {
      mobile: {
        xPos: '80%',
        yPos: '44%',
      },
      desktop: {
        xPos: '80%',
        yPos: '37%',
      },
    },
    details: adaptiveLedHeadlamp.details,
    zoomImage: {
      src: adaptiveLedHeadlamp.zoomImages.streetGlide,
      alt: adaptiveLedHeadlamp.details.title,
    },
  },
  // seat
  {
    coordinates: {
      mobile: {
        xPos: '35%',
        yPos: '56%',
      },
      desktop: {
        xPos: '35%',
        yPos: '52%',
      },
    },
    details: hammockSeat.details,
    zoomImage: {
      src: hammockSeat.zoomImages.all,
      alt: hammockSeat.details.title,
    },
    isUpgrade: true,
  },
  // heavy breather
  {
    coordinates: {
      mobile: {
        xPos: '60%',
        yPos: '63%',
      },
      desktop: {
        xPos: '60%',
        yPos: '63%',
      },
    },
    details: heavyBreather.details,
    zoomImage: {
      src: heavyBreather.zoomImages.streetGlide,
      alt: heavyBreather.details.title,
    },
  },
];

export const roadGlideHotspots: Array<Hotspot> = [
  {
    coordinates: {
      mobile: {
        xPos: '68%',
        yPos: '32%',
      },
      desktop: {
        xPos: '68%',
        yPos: '30%',
      },
    },
    details: kahunaCollection.details,
    zoomImage: {
      src: kahunaCollection.zoomImages.roadGlide,
      alt: kahunaCollection.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '93%',
        yPos: '90%',
      },
      desktop: {
        xPos: '95%',
        yPos: '88%',
      },
    },
    details: exclusiveComboWheels.details,
    zoomImage: {
      src: exclusiveComboWheels.zoomImages.roadGlide,
      alt: exclusiveComboWheels.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '55%',
        yPos: '70%',
      },
      desktop: {
        xPos: '55%',
        yPos: '70%',
      },
    },
    details: milwaukeeEight.details,
    zoomImage: {
      src: milwaukeeEight.zoomImages.roadGlide,
      alt: milwaukeeEight.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '23%',
        yPos: '62%',
      },
      desktop: {
        xPos: '24%',
        yPos: '56%',
      },
    },
    details: audioRockfordFosgate.details,
    zoomImage: {
      src: audioRockfordFosgate.zoomImages.roadGlide,
      alt: audioRockfordFosgate.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '35%',
        yPos: '56%',
      },
      desktop: {
        xPos: '35%',
        yPos: '52%',
      },
    },
    details: hammockSeat.details,
    zoomImage: {
      src: hammockSeat.zoomImages.all,
      alt: hammockSeat.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '60%',
        yPos: '63%',
      },
      desktop: {
        xPos: '60%',
        yPos: '63%',
      },
    },
    details: heavyBreather.details,
    zoomImage: {
      src: heavyBreather.zoomImages.roadGlide,
      alt: heavyBreather.details.title,
    },
  },
];

export const triGlideHotspots: Array<Hotspot> = [
  {
    coordinates: {
      mobile: {
        xPos: '68%',
        yPos: '32%',
      },
      desktop: {
        xPos: '68%',
        yPos: '30%',
      },
    },
    details: kahunaCollection.details,
    zoomImage: {
      src: kahunaCollection.zoomImages.triGlide,
      alt: kahunaCollection.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '93%',
        yPos: '90%',
      },
      desktop: {
        xPos: '95%',
        yPos: '88%',
      },
    },
    details: tomahawkWheels.details,
    zoomImage: {
      src: tomahawkWheels.zoomImages.triGlide,
      alt: tomahawkWheels.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '23%',
        yPos: '62%',
      },
      desktop: {
        xPos: '24%',
        yPos: '56%',
      },
    },
    details: boomStageSpeakers.details,
    zoomImage: {
      src: boomStageSpeakers.zoomImages.triGlide,
      alt: boomStageSpeakers.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '13%',
        yPos: '39%',
      },
      desktop: {
        xPos: '13%',
        yPos: '39%',
      },
    },
    details: audioRockfordFosgate.details,
    zoomImage: {
      src: audioRockfordFosgate.zoomImages.triGlide,
      alt: audioRockfordFosgate.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '80%',
        yPos: '44%',
      },
      desktop: {
        xPos: '80%',
        yPos: '37%',
      },
    },
    details: {
      title: 'All LED Lightning',
      description: 'Headlamp, Fog Lamps, Tail/Brake Lamps and Signals',
    },
    zoomImage: {
      src: TriGlideAllLedLightning,
      alt: 'All LED Lightning',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '55%',
        yPos: '70%',
      },
      desktop: {
        xPos: '55%',
        yPos: '70%',
      },
    },
    details: twinMilwaukeeEight.details,
    zoomImage: {
      src: twinMilwaukeeEight.zoomImages.triGlide,
      alt: twinMilwaukeeEight.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '35%',
        yPos: '56%',
      },
      desktop: {
        xPos: '35%',
        yPos: '52%',
      },
    },
    details: hammockSeat.details,
    zoomImage: {
      src: hammockSeat.zoomImages.all,
      alt: hammockSeat.details.title,
    },
    isUpgrade: true,
  },
];
export const limitedHotspots: Array<Hotspot> = [
  {
    coordinates: {
      mobile: {
        xPos: '68%',
        yPos: '32%',
      },
      desktop: {
        xPos: '68%',
        yPos: '30%',
      },
    },
    details: kahunaCollection.details,
    zoomImage: {
      src: kahunaCollection.zoomImages.limited,
      alt: kahunaCollection.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '93%',
        yPos: '90%',
      },
      desktop: {
        xPos: '95%',
        yPos: '88%',
      },
    },
    details: tomahawkWheels.details,
    zoomImage: {
      src: tomahawkWheels.zoomImages.limited,
      alt: tomahawkWheels.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '23%',
        yPos: '62%',
      },
      desktop: {
        xPos: '24%',
        yPos: '56%',
      },
    },
    details: boomStageSpeakers.details,
    zoomImage: {
      src: boomStageSpeakers.zoomImages.limited,
      alt: boomStageSpeakers.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '80%',
        yPos: '44%',
      },
      desktop: {
        xPos: '80%',
        yPos: '37%',
      },
    },
    details: adaptiveLedHeadlamp.details,
    zoomImage: {
      src: adaptiveLedHeadlamp.zoomImages.limited,
      alt: adaptiveLedHeadlamp.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '55%',
        yPos: '70%',
      },
      desktop: {
        xPos: '55%',
        yPos: '70%',
      },
    },
    details: twinMilwaukeeEight.details,
    zoomImage: {
      src: twinMilwaukeeEight.zoomImages.limited,
      alt: twinMilwaukeeEight.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '23%',
        yPos: '62%',
      },
      desktop: {
        xPos: '24%',
        yPos: '56%',
      },
    },
    details: audioRockfordFosgate.details,
    zoomImage: {
      src: audioRockfordFosgate.zoomImages.limited,
      alt: audioRockfordFosgate.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '35%',
        yPos: '56%',
      },
      desktop: {
        xPos: '35%',
        yPos: '52%',
      },
    },
    details: hammockSeat.details,
    zoomImage: {
      src: hammockSeat.zoomImages.all,
      alt: hammockSeat.details.title,
    },
    isUpgrade: true,
  },
];
