import { addEventListener } from 'seng-disposable-event-listener';

export default function setViewportCustomProperties(): () => void {
  const onResize = () => {
    const { innerHeight } = window;
    document.documentElement.style.setProperty('--vh', `${innerHeight * 0.01}px`);
  };

  onResize();
  return addEventListener(window, 'resize', onResize);
}
