import type { Image, Hotspot, ResponsiveImage, Sketch, Coordinates } from '../types/global';
import { streetBob114Hotspots } from './hotspots/streetBob114';
import StreetBob114BikeImageMobile from '../assets/images/street-bob-114/mobile/street-bob-mobile.png';
import StreetBob114BikeImage from '../assets/images/street-bob-114/desktop/bike.png';
import StreetBob114BikeImageHover from '../assets/images/street-bob-114/desktop/bike-hover.png';
import EngineMobileImage from '../assets/images/street-bob-114/mobile/engine.jpg';
import EngineDesktopImage from '../assets/images/street-bob-114/desktop/engine.jpg';
import LightBeam from '../assets/images/street-bob-114/desktop/lights/1-street-bob-leds-light-beam-compressed.png';
import HazeBlue from '../assets/images/street-bob-114/desktop/lights/2-street-bob-light-haze-blue-compressed.png';
import HazeWhite from '../assets/images/street-bob-114/desktop/lights/3-street-bob-light-haze-white-compressed.png';

// Sketch assets
import SketchStreetBob114 from '../assets/images/street-bob-114/sketch/sketch-street-bob.png';
import SketchStreetBob114Colored from '../assets/images/street-bob-114/sketch/hover-street-bob.png';

interface StreetBob114Data {
  title: string;
  eyebrow: string;
  description: string;
  backgroundText: string;
  bikeImageBrightMobile: Image;
  bikeImageBright: Image;
  bikeImageHover: Image;
  bikeImageLights: {
    lightBeam: Image;
    hazeBlue: Image;
    hazeWhite: Image;
  };
  hotspots: Array<Hotspot>;
  sketch: Sketch;
  signature: string;
  posterImage: ResponsiveImage;
  bikeShopButtonPosition: {
    mobile: Coordinates;
    desktop: Coordinates;
  };
  engineSection: {
    title: string;
    description: string;
    tableContent: Array<{
      type: string;
      value: string;
    }>;
  };
}

export const streetBob114Data: StreetBob114Data = {
  title: "Let's Kick It Up",
  eyebrow: 'Street Bob 114',
  description:
    'The lightest weight softail with the Milwaukee-Eight 114 engine in the lineup is reborn in 2021 with a heavy-weight punch and some extra attitude to match. The bobber styling remains unchanged but the 2021 Street Bob® now rolls with the Milwaukee-Eight® 114 engine for a guaranteed knockout.',
  backgroundText: 'Street Bob 114',
  bikeImageLights: {
    lightBeam: {
      src: LightBeam,
      alt: 'Street Bob 114',
    },
    hazeBlue: {
      src: HazeBlue,
      alt: 'Street Bob 114',
    },
    hazeWhite: {
      src: HazeWhite,
      alt: 'Street Bob 114',
    },
  },
  bikeImageBrightMobile: {
    src: StreetBob114BikeImageMobile,
    alt: 'Street Bob 114',
  },
  bikeImageBright: {
    src: StreetBob114BikeImage,
    alt: 'Street Bob 114',
  },
  bikeImageHover: {
    src: StreetBob114BikeImageHover,
    alt: 'Street Bob 114',
  },
  hotspots: streetBob114Hotspots,
  sketch: {
    initial: {
      src: SketchStreetBob114,
      alt: 'Street Bob 114',
    },
    hover: {
      src: SketchStreetBob114Colored,
      alt: 'Street Bob 114',
    },
  },
  signature: 'Street Bob 114',
  posterImage: {
    mobile: {
      src: EngineMobileImage,
      alt: 'Street Bob 114 Engine',
    },
    desktop: {
      src: EngineDesktopImage,
      alt: 'Street Bob 114 Engine',
    },
  },
  engineSection: {
    title: 'Milwaukee-Eight® 114 Engine',
    description:
      'The most-powerful Softail® powertrain option, delivers inspiring performance and classic Harley-Davidson look-sound-feel. For the rider focused on performance this engine accepts all suitable Harley-Davidson® Screamin’ Eagle® Stage Upgrade kits.',
    tableContent: [
      {
        type: 'Engine Torque',
        value: '119 ft-lbs (161 Nm)',
      },
      {
        type: 'Engine Torque (rpm)',
        value: '3000',
      },
      {
        type: 'Bore',
        value: '4.016 in (102 mm)',
      },
      {
        type: 'Stroke',
        value: '4.5 in (114 mm)',
      },
      {
        type: 'Displacement',
        value: '114 cu in (1,868 cc)',
      },
      {
        type: 'Compression Ratio',
        value: '10:5:1',
      },
    ],
  },
  bikeShopButtonPosition: {
    mobile: {
      xPos: '30%',
      yPos: '23%',
    },
    desktop: {
      xPos: '62%',
      yPos: '24%',
    },
  },
};
