import { createGlobalStyle } from 'styled-components';
import { size } from 'polished';
import theme from './theme/theme';
import { vh } from './styles/helpers/vh';
import { respondTo } from './styles/helpers/respondTo';
import { MediaQuery } from './styles/mediaQuery';

const GlobalStyle = createGlobalStyle`
  :root {
    --x-offset: 0;
    --y-offset: 0;
    // PLyr overwrites
    --plyr-color-main: ${theme.color.orange};
    --plyr-badge-background: ${theme.color.darkGray};

    .plyr__volume {
      width: 36px;
      min-width: 36px;
    }

    .plyr--video {
      width: 100%;
      height: auto;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  ::selection { background: rgba(255, 201, 176, .6); }

  body {
    overflow-x: hidden !important;
    
    overscroll-behavior: none;
    font-family: ${theme.fonts.attributeMono}, sans-serif;
    background: ${theme.color.darkGray};
    color: ${theme.color.black};
    ${size(vh(100), '100%')};
    margin: 0;
    padding: 0;
    
    @media ${respondTo(MediaQuery.LARGE)}{
      overflow-y: hidden !important;
    }
  }

  a {
    color: currentColor;
    display: block;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    font-weight: normal;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  input,
  select {
    border: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button {
    cursor: pointer;
    background: transparent;
    font-family: ${theme.fonts.attributeMono}, sans-serif;
    border: none;
    padding: 0;
  }

  *:focus {
    outline: 1px solid ${theme.color.sienna};
  }


  .full-bg-black {
    position: fixed;
    top: 0;
    width: 100%;
    height: ${vh(100)};
    background-color: black;
  }
`;

export default GlobalStyle;
