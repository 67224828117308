import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import type { RouteComponentProps } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { ErrorBoundary } from '../other/ErrorBoundary/ErrorBoundary';
import { useGlobalStore } from '../../GlobalStore';
import { Paths } from '../../data/enum/paths';
import { Phases } from '../../data/enum/phases';

const CountdownPage = React.lazy(
  () => import(/* webpackChunkName: "CountdownPage" */ './CountdownPage'),
);
const PanamOverview = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PanamOverviewPage" */ '../organisms/Sections/PanamOverview/PanamOverview'
    ),
);
const RidingGear = React.lazy(
  () =>
    import(/* webpackChunkName: "RigingGearPage" */ '../organisms/Sections/RidingGear/RidingGear'),
);

const Rides = React.lazy(
  () => import(/* webpackChunkName: "RidesPage" */ '../organisms/Sections/Rides/Rides'),
);
const TheRoadHere = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TheRoadHerePage" */ '../organisms/Sections/TheRoadHere/TheRoadHere'
    ),
);

const FAQPage = React.lazy(() => import('./FAQ'));
const TakeTheRide = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TakeTheRidePage" */ '../organisms/Sections/TakeTheRide/TakeTheRide'
    ),
);
const RidesContent = React.lazy(
  () =>
    import(/* webpackChunkName: "RidesContentPage" */ '../organisms/Sections/Rides/RidesContent'),
);

const DealerZone = React.lazy(
  () =>
    import(/* webpackChunkName: "DealerZonePage" */ '../organisms/Sections/DealerZone/DealerZone'),
);

// preserves query params on redirect
const getRedirect = (path: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const RedirectWithParams: React.FC<RouteComponentProps<{}>> = ({ location }) => {
    return <Redirect to={{ pathname: path, search: location.search }} />;
  };

  return RedirectWithParams;
};

export const Routes: React.FC = observer(() => {
  const store = useGlobalStore();

  if (store.currentPhase === Phases.COUNTDOWN) {
    return (
      <ErrorBoundary showFallback>
        <Suspense fallback={<div className="full-bg-black" />}>
          <Switch>
            <Route exact path={Paths.COUNTDOWN} component={CountdownPage} />
            <Route render={getRedirect(Paths.COUNTDOWN)} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    );
  }

  // TODO: improve loading fallback
  return (
    <ErrorBoundary showFallback>
      <Suspense fallback={<div className="full-bg-black" />}>
        <>
          <AnimatePresence>
            <Switch>
              <Route exact path={Paths.THE_ROAD_HERE} component={TheRoadHere} />
              <Route exact path={Paths.PANAM_OVERVIEW} component={PanamOverview} />
              <Route exact path={Paths.RIDING_GEAR} component={RidingGear} />
              <Route exact path={Paths.EQUIPPED_FOR_ADVENTURE} component={Rides} />
              <Route exact path={Paths.EQUIPPED_FOR_ADVENTURE_FROM_CONTENT} component={Rides} />
              <Route exact path={`${Paths.EQUIPPED_FOR_ADVENTURE}/:id`} component={RidesContent} />
              <Route exact path={Paths.EQUIPPED_FOR_ADVENTURE} component={Rides} />
              <Route exact path={Paths.TAKE_THE_RIDE} component={TakeTheRide} />
              <Route exact path={Paths.FAQ} component={FAQPage} />
              <Route exact path={Paths.DEALER_ZONE} component={DealerZone} />
              <Route render={getRedirect(Paths.THE_ROAD_HERE)} />
            </Switch>
          </AnimatePresence>
        </>
      </Suspense>
    </ErrorBoundary>
  );
});
