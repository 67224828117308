import hexToRgba from 'hex-to-rgba';

const theme = {
  color: {
    orange: '#f26a2b',

    // PANAM
    black: '#000',
    lighterBlack: '#161B20',
    white: '#FFF',
    offwhite: '#F1F1F1',
    sienna: '#CD6557',
    // Grey shades
    darkGray: '#25292E',
    gray: '#525252',
    lightGray: '#CCCCCC',
    lightestGray: '#DFDFDF',

    // Blue shades
    lightBlue: '#BBC7D1',
    lightestBlue: '#DBE5EB',
    // Concrete shades
    concrete: '#71757E',
    lightConcrete: '#E8E6E9',
    // Sand shades
    clay: '#B8846A',
    lightClay: '#EBE7E2',
    // Green shades
    green: '#8C9480',
    lightGreen: '#ECEFE6',
  },
  // Utils
  utils: {
    // https://www.npmjs.com/package/hex-to-rgba
    hexToRgba,
  },
  menu: {
    width: {
      collapsed: '90px',
      expanded: '288px',
    },
    mobileHeight: '83px', // leave it for now to avoid conflicts with typescript
    height: {
      desktop: '100px',
      mobile: '60px',
    },
  },
  fonts: {
    franklinGothicAtf: 'franklin-gothic-atf',
    attributeMono: 'attribute-mono',
    franklinGothicCompressed: 'franklin-gothic-compressed',
  },
  easing: {
    transitionIn: 'cubic-bezier(0.2, 0, 0, 1)',
  },
  floatingImageSize: '31vw',
  sitePadding: {
    mobile: '9.7%',
  },
};

export default theme;

type CustomTheme = typeof theme;

export type ThemeColors = keyof typeof theme.color;

export type ColorProp = {
  $color?: ThemeColors;
};

// this will redeclare the global `DefaultTheme` so it is aware of our custom theme.
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends CustomTheme {}
}
