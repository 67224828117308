// const getCurrentTime = async (): Promise<number> => {
//   try {
//     const response = await fetch('<service_api>');

//     if (response.ok) {
//       const { currentDateTime } = await response.json();
//       return new Date(currentDateTime).getTime();
//     }

//     return Date.now();
//   } catch (error) {
//     return Date.now();
//   }
// };

import type { Timestamp } from '../types/opaque';
import { getUrlSearchParams } from './getUrlSearchParams';

export const getCurrentTime = (): Timestamp => {
  // TODO: only allow staging and localhost
  const params = getUrlSearchParams();

  if ('currentTime' in params) {
    const date = new Date(params.currentTime).getTime() as Timestamp;
    if (!Number.isNaN(date)) return date;
  }

  return Date.now() as Timestamp;
};
