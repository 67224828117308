/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LOCALE_LOCALSTORAGE, LOCALE_QUERYPARAM, DEFAULT_LANG } from '../constants';
import en_US from '../data/locale/en_US.json';
import { mapLocale } from './mapLocale';
import { setFindADealerByLocale, getFindDealerLink } from './getFindDealerLink';

export const initI18n = async () => {
  const localeFromStorage = localStorage.getItem(LOCALE_LOCALSTORAGE) || '';
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsEntries = Object.fromEntries(urlParams.entries());

  if (!localeFromStorage && !('locale' in urlParamsEntries)) {
    // in case there is not queryParam setted we force an invalid queryParam
    urlParams.append(LOCALE_QUERYPARAM, '0');
    window.location.search = urlParams.toString();

    // url locale will be deleted in App component
  }

  const instance = i18n.createInstance();
  await instance
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        [DEFAULT_LANG]: en_US,
      },
      fallbackLng: DEFAULT_LANG,
      returnObjects: true,
      detection: {
        order: ['localStorage', 'querystring'],
        lookupQuerystring: LOCALE_QUERYPARAM,
        lookupLocalStorage: LOCALE_LOCALSTORAGE,
      },
      debug: process.env.NODE_ENV === 'development',
    });

  const localeParamValue = urlParams.get(LOCALE_QUERYPARAM) || localeFromStorage;

  // set find a dealer link
  setFindADealerByLocale(getFindDealerLink(localeParamValue));

  let locale = mapLocale(localeParamValue);

  // even though the lybrary set the locale in localstorage sometimes it doesn't
  localStorage.setItem(LOCALE_LOCALSTORAGE, locale);

  if (locale === 'en_EU') {
    locale = 'en_US';
  }

  if (locale === DEFAULT_LANG) {
    return Promise.resolve();
  }

  const localeFile = await import(`../data/locale/${locale}.json`).catch(() => {
    console.warn(`Error getting ${locale} locale. Fallback to ${DEFAULT_LANG}`);
  });

  if (!localeFile) {
    return Promise.resolve();
  }

  Object.entries(localeFile.default).forEach(([namespace, values]) => {
    instance.addResourceBundle(locale, namespace, values);
  });

  // if (instance.language !== locale) {
  instance.changeLanguage(locale);
  // }

  Promise.resolve();
};
