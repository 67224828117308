import React, { FunctionComponent } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback/ErrorBoundaryFallback';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  showFallback?: boolean;
};

const Empty = () => null;

// wrap any section of the app with this component
// if any error is thrown within that section, we display a fallback instead of white screen
export const ErrorBoundary: FunctionComponent<ErrorBoundaryProps> = ({
  children,
  showFallback = true, // TODO: change this to false before going live
}) => {
  return (
    <ReactErrorBoundary FallbackComponent={showFallback ? ErrorBoundaryFallback : Empty}>
      {children}
    </ReactErrorBoundary>
  );
};
