import type { Hotspot } from '../../types/global';
import SoftailHighFlowZoom from '../../assets/images/street-bob-114/zoomed-images/softail-high-flow-zoom.png';
import StageIIIKitSoftailZoom from '../../assets/images/street-bob-114/zoomed-images/stage-iii-kit-softail-zoom.png';
import LegendaryPaintZoom from '../../assets/images/street-bob-114/zoomed-images/legendary-paint-zoom.png';
import LedTurnSignalZoom from '../../assets/images/street-bob-114/zoomed-images/led-turn-signal-zoom.png';

export const streetBob114Hotspots: Array<Hotspot> = [
  {
    coordinates: {
      mobile: {
        xPos: '10%',
        yPos: '67%',
      },
      desktop: {
        xPos: '0%',
        yPos: '28%',
      },
    },
    details: {
      title: 'Softail High Flow 2-into-1 Exhaust',
      description:
        "The Screamin' Eagle High-Flow Exhaust for Softail provides bolt-on performance and is optimized for use with the Screamin' Eagle 128/131 Stage IV Kits or 131 Crate Engine equipped Milwaukee-Eight Softails. Maximize the horsepower and torque of your performance build, while reducing the weight of your exhaust system by over 20 percent when compared to the factory exhaust system. Offered in durable Satin Black or Stainless finishes.",
      coordinates: {
        xPos: '0',
        yPos: '110%',
      },
      size: 'medium',
    },
    zoomImage: {
      src: SoftailHighFlowZoom,
      alt: 'Softail High Flow 2-into-1 Exhaust',
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '25%',
        yPos: '34%',
      },
      desktop: {
        xPos: '21%',
        yPos: '41%',
      },
    },
    details: {
      title: '2-up Seating',
      description:
        'With a passenger pillion and foot pegs, there’s room for two on the Street Bob.',
      size: 'small',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '32%',
        yPos: '71%',
      },
      desktop: {
        xPos: '30%',
        yPos: '29%',
      },
    },
    details: {
      title: 'Stage III Kit for Softail - 122 Engine',
      description:
        'Increase the displacement of your Milwaukee-Eight® engine without removing the engine from the chassis. This Stage III kit has been developed for a solid response you can feel, every time you roll on the throttle.',
      coordinates: {
        xPos: '7%',
        yPos: '110%',
      },
      size: 'large',
    },
    zoomImage: {
      src: StageIIIKitSoftailZoom,
      alt: 'Stage III Kit for Softail - 122 Engine',
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '56.5%',
        yPos: '4%',
      },
      desktop: {
        xPos: '45%',
        yPos: '23%',
      },
    },
    details: {
      title: 'Mini Ape Handlebar',
      description:
        'The Mini Apes provide classic bobber-style of riding with your fists in the wind, without a compromise to your comfort.',
      size: 'medium',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '51%',
        yPos: '35%',
      },
      desktop: {
        xPos: '40%',
        yPos: '11%',
      },
    },
    details: {
      title: 'Legendary Paint',
      description:
        'A modern take on our classic racing “Number One” on the fuel tank, new for 2021 in 4 colors: Vivid Black, Stonewashed White Pearl, Deadwood Green, Baja Orange',
      coordinates: {
        xPos: '0%',
        yPos: '110%',
      },
      size: 'small',
    },
    zoomImage: {
      src: LegendaryPaintZoom,
      alt: 'Legendary paint',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '69%',
        yPos: '17%',
      },
      desktop: {
        xPos: '80%',
        yPos: '37%',
      },
    },
    details: {
      title: 'LED Turn Signal Inserts',
      description:
        'Signature style and modern functionality that improves rider conspicuity to surrounding traffic. The light pipe outer “signature” ring adds visibility and unique styling, while brilliant center LED elements flash with an eye-catching signal. ',
      coordinates: {
        xPos: '-140%',
        yPos: '0%',
      },
      size: 'medium',
    },
    zoomImage: {
      src: LedTurnSignalZoom,
      alt: 'LED Turn Signal Inserts',
    },
    isUpgrade: true,
  },

  {
    coordinates: {
      mobile: {
        xPos: '89%',
        yPos: '54%',
      },
      desktop: {
        xPos: '55%',
        yPos: '66%',
      },
    },
    details: {
      title: 'Black Laced Wheels',
      description: 'Lightweight, enduring style — nothing says “classic” like spoke wheels.',
      size: 'medium',
    },
  },
];
