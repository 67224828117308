/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line no-shadow
export enum Paths {
  SITE = '/',
  COUNTDOWN = '/countdown',
  FAQ = '/faq',
  PANAM_OVERVIEW = '/pan-america-1250',
  RIDING_GEAR = '/riding-gear',
  EQUIPPED_FOR_ADVENTURE = '/equipped-for-adventure',
  EQUIPPED_FOR_ADVENTURE_FROM_CONTENT = '/equipped-for-adventure/back',
  THE_ROAD_HERE = '/the-road-here',
  DEALER_ZONE = '/dealer-zone',
  TAKE_THE_RIDE = '/take-the-ride',
}
