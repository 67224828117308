/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum Breakpoints {
  X_SMALL = 479,
  SMALL = 480,
  MEDIUM = 768,
  LARGE = 1024,
  X_LARGE = 1280,
  XX_LARGE = 1440,
  XXX_LARGE = 1920,
  XXXX_LARGE = 2560,
}

export enum MediaQuery {
  X_SMALL = '(max-width: 479px)',
  SMALL = '(min-width: 480px)',
  MEDIUM = '(min-width: 768px)',
  MAX_LARGE = '(max-width: 1023px)',
  LARGE = '(min-width: 1024px)',
  X_LARGE = '(min-width: 1280px)',
  XX_LARGE = '(min-width: 1440px)',
  XXX_LARGE = '(min-width: 1920px)',
  XXXX_LARGE = '(min-width: 2560px)',
}
