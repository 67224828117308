import type { Image, Bike } from '../types/global';
import PosterImage from '../assets/images/touring/chrome-images/chrome-side-image.jpg';

import {
  billiardRed,
  billiardRedVividBlack,
  billiardTeal,
  blackJackMetallic,
  deadwoodGreen,
  gauntletGrayVividBlack,
  midnightCrimson,
  snakeVenom,
  vividBlack,
} from './enum/bikeColors';

import {
  streetGlideSpecialHotspots,
  roadGlideSpecialHotspots,
  roadKingSpecialHotspots,
} from './hotspots/touring';

// Chrome
import ChromeDarkBike from '../assets/images/touring/chrome-images/road-glide-special-black-1.png';
import ChromeLightBike from '../assets/images/touring/chrome-images/road-glide-special-black-2.png';
import ChromeTextureVideo from '../assets/videos/chrome-texture-video.mp4';

// Street Glide Special
import StreetGlideSpecialBilliardRed from '../assets/images/touring/street-glide-special/street-glide-special-billiard-red.png';
import StreetGlideSpecialBlack from '../assets/images/touring/street-glide-special/street-glide-special-black.png';
import StreetGlideSpecialMetallicBlack from '../assets/images/touring/street-glide-special/street-glide-special-metallic-black.png';
import StreetGlideSpecialMetallic from '../assets/images/touring/street-glide-special/street-glide-special-metallic.png';
import StreetGlideSpecialSnakeVenom from '../assets/images/touring/street-glide-special/street-glide-special-snake-venom.png';
import SketchStreetGlideSpecial from '../assets/images/touring/street-glide-special/sketch-street-glide-special.png';

// Road Glide Special
import RoadGlideSpecialBilliardTeal from '../assets/images/touring/road-glide-special/road-glide-special-billiard-teal.png';
import RoadGlideSpecialBrightChrome from '../assets/images/touring/road-glide-special/road-glide-special-bright-chrome.png';
import RoadGlideSpecialMidnightCrimson from '../assets/images/touring/road-glide-special/road-glide-special-midnight-crimson.png';
import RoadGlideSpecialRedBlack from '../assets/images/touring/road-glide-special/road-glide-special-red-black.png';
import RoadGlideSpecialSnakeVenom from '../assets/images/touring/road-glide-special/road-glide-special-snake-venom.png';
import SketchRoadGlideSpecial from '../assets/images/touring/road-glide-special/sketch-road-glide-special.png';

// Road King Special
import RoadKingSpecialBlack from '../assets/images/touring/road-king-special/road-king-special-black.png';
import RoadKingSpecialDeadwoodGreen from '../assets/images/touring/road-king-special/road-king-special-deadwood-green.png';
import RoadKingSpecialMetallic from '../assets/images/touring/road-king-special/road-king-special-metallic.png';
import RoadKingSpecialSnakeVenom from '../assets/images/touring/road-king-special/road-king-special-snake-venom.png';
import SketchRoadKingSpecial from '../assets/images/touring/road-king-special/sketch-road-king-special.png';

interface TouringData {
  title: string;
  eyebrow: string;
  description: string;
  bgTextCopy: string;
  chromeBike: {
    dark: Image;
    light: Image;
    textureVideo: string;
  };
  posterImage: Image;
  bikes: Array<Bike>;
}

export const touringData: TouringData = {
  title: 'CHROME IS HOME',
  eyebrow: 'SPECIALS',
  description:
    'Street Glide® Special and Road Glide® Special models may be ordered with either chrome or dark styling. Chrome replaces black components on the dark version: Powertrain covers and trim, front end, handlebar, exhaust, tank console and other details.',
  bgTextCopy: 'Specials',
  chromeBike: {
    dark: {
      src: ChromeDarkBike,
      alt: 'Road Glide Special Black',
    },
    light: {
      src: ChromeLightBike,
      alt: 'Road Glide Special Black',
    },
    textureVideo: ChromeTextureVideo,
  },
  posterImage: {
    src: PosterImage,
    alt: 'Person riding Harley Davidson motorcycle',
  },
  bikes: [
    {
      name: '2021 STREET GLIDE® SPECIAL',
      description:
        'The custom hot rod bagger look stops people in their tracks. Powered by the Milwaukee-Eight® 114 engine and a new LED headlamp, they’ll see you coming as you leave them in your dust. It comes with blacked-out and chrome finishes.',
      sketch: {
        initial: {
          src: SketchStreetGlideSpecial,
          alt: '2021 STREET GLIDE® SPECIAL',
        },
        hover: {
          src: StreetGlideSpecialBilliardRed,
          alt: '2021 STREET GLIDE® SPECIAL',
        },
      },
      signature: 'Street Glide Special',
      variants: [
        { bikeColor: vividBlack, bikeImage: StreetGlideSpecialBlack },
        { bikeColor: blackJackMetallic, bikeImage: StreetGlideSpecialMetallicBlack },
        { bikeColor: billiardRed, bikeImage: StreetGlideSpecialBilliardRed },
        { bikeColor: gauntletGrayVividBlack, bikeImage: StreetGlideSpecialMetallic },
        { bikeColor: snakeVenom, bikeImage: StreetGlideSpecialSnakeVenom },
      ],
      hotspots: streetGlideSpecialHotspots,
    },
    {
      name: '2021 ROAD GLIDE® SPECIAL',
      description:
        'This bright chrome or blacked-out performance bagger comes loaded with premium features. If you’re seeking a classic touring design with never before offered two-tone paint schemes, then fire up the thrill-inducing Milwaukee-Eight® 114 V-Twin engine, and you’ll be in for one hell of a ride.',
      sketch: {
        initial: {
          src: SketchRoadGlideSpecial,
          alt: '2021 ROAD GLIDE® SPECIAL',
        },
        hover: {
          src: RoadGlideSpecialBilliardTeal,
          alt: '2021 ROAD GLIDE® SPECIAL',
        },
      },
      signature: 'Road Glide Special',
      variants: [
        { bikeColor: vividBlack, bikeImage: RoadGlideSpecialBrightChrome },
        { bikeColor: midnightCrimson, bikeImage: RoadGlideSpecialMidnightCrimson },
        { bikeColor: billiardTeal, bikeImage: RoadGlideSpecialBilliardTeal },
        { bikeColor: billiardRedVividBlack, bikeImage: RoadGlideSpecialRedBlack },
        { bikeColor: snakeVenom, bikeImage: RoadGlideSpecialSnakeVenom },
      ],
      hotspots: roadGlideSpecialHotspots,
    },
    {
      name: '2021 ROAD KING® SPECIAL',
      description:
        'You’re looking at the king of our dark side. You get top-of-the-line power, iconic H-D style, and a blacked-out look that will stop traffic. This custom bagger has a commanding presence, from the Milwaukee-Eight® 114 V-Twin engine to the new LED headlight and new low profile console. Black finish options available only.',
      sketch: {
        initial: {
          src: SketchRoadKingSpecial,
          alt: '2021 ROAD KING® SPECIAL',
        },
        hover: {
          src: RoadKingSpecialDeadwoodGreen,
          alt: '2021 ROAD KING® SPECIAL',
        },
      },
      signature: 'Road King Special',
      variants: [
        { bikeColor: vividBlack, bikeImage: RoadKingSpecialBlack },
        { bikeColor: blackJackMetallic, bikeImage: RoadKingSpecialMetallic },
        { bikeColor: deadwoodGreen, bikeImage: RoadKingSpecialDeadwoodGreen },
        { bikeColor: snakeVenom, bikeImage: RoadKingSpecialSnakeVenom },
      ],
      hotspots: roadKingSpecialHotspots,
    },
  ],
};
