/* eslint-disable max-lines */

import type { Hotspot } from '../../types/global';
import RoadKingSpecialNewTankMedallion from '../../assets/images/touring/road-king-special/hotspots/new-tank-medallion.jpg';

import {
  audioRockfordFosgate,
  brakingLights,
  consoleFuelFiller,
  daymakerLedHeadlamp,
  engineGuard,
  hammockSeat,
  ventilatorAirCleaner,
} from './global';

export const streetGlideSpecialHotspots: Array<Hotspot> = [
  {
    coordinates: {
      mobile: {
        xPos: '56.5%',
        yPos: '36%',
      },
      desktop: {
        xPos: '56.5%',
        yPos: '36%',
      },
    },
    details: consoleFuelFiller.details,
    zoomImage: {
      src: consoleFuelFiller.zoomImages.streetGlideSpecial,
      alt: consoleFuelFiller.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '70%',
        yPos: '78%',
      },
      desktop: {
        xPos: '70%',
        yPos: '78%',
      },
    },
    details: engineGuard.details,
    zoomImage: {
      src: engineGuard.zoomImages.streetGlideSpecial,
      alt: engineGuard.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '83%',
        yPos: '39%',
      },
      desktop: {
        xPos: '83%',
        yPos: '39%',
      },
    },
    details: daymakerLedHeadlamp.details,
    zoomImage: {
      src: daymakerLedHeadlamp.zoomImages.streetGlideSpecial,
      alt: daymakerLedHeadlamp.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '2%',
        yPos: '63%',
      },
      desktop: {
        xPos: '2%',
        yPos: '63%',
      },
    },
    details: brakingLights.details,
    zoomImage: {
      src: brakingLights.zoomImages.all,
      alt: brakingLights.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '39%',
        yPos: '49%',
      },
      desktop: {
        xPos: '39%',
        yPos: '49%',
      },
    },
    details: hammockSeat.details,
    zoomImage: {
      src: hammockSeat.zoomImages.all,
      alt: hammockSeat.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '25%',
        yPos: '53%',
      },
      desktop: {
        xPos: '25%',
        yPos: '53%',
      },
    },
    details: audioRockfordFosgate.details,
    zoomImage: {
      src: audioRockfordFosgate.zoomImages.streetGlideSpecial,
      alt: audioRockfordFosgate.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '58.5%',
        yPos: '65%',
      },
      desktop: {
        xPos: '58.5%',
        yPos: '65%',
      },
    },
    details: ventilatorAirCleaner.details,
    zoomImage: {
      src: ventilatorAirCleaner.zoomImages.streetGlideSpecial,
      alt: ventilatorAirCleaner.details.title,
    },
  },
];

export const roadGlideSpecialHotspots: Array<Hotspot> = [
  {
    coordinates: {
      mobile: {
        xPos: '56.5%',
        yPos: '36%',
      },
      desktop: {
        xPos: '56.5%',
        yPos: '36%',
      },
    },
    details: consoleFuelFiller.details,
    zoomImage: {
      src: consoleFuelFiller.zoomImages.roadGlideSpecial,
      alt: consoleFuelFiller.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '70%',
        yPos: '78%',
      },
      desktop: {
        xPos: '70%',
        yPos: '78%',
      },
    },
    details: engineGuard.details,
    zoomImage: {
      src: engineGuard.zoomImages.roadGlideSpecial,
      alt: engineGuard.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '2%',
        yPos: '63%',
      },
      desktop: {
        xPos: '2%',
        yPos: '63%',
      },
    },
    details: brakingLights.details,
    zoomImage: {
      src: brakingLights.zoomImages.all,
      alt: brakingLights.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '39%',
        yPos: '49%',
      },
      desktop: {
        xPos: '39%',
        yPos: '49%',
      },
    },
    details: hammockSeat.details,
    zoomImage: {
      src: hammockSeat.zoomImages.all,
      alt: hammockSeat.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '25%',
        yPos: '53%',
      },
      desktop: {
        xPos: '25%',
        yPos: '53%',
      },
    },
    details: audioRockfordFosgate.details,
    zoomImage: {
      src: audioRockfordFosgate.zoomImages.roadGlideSpecial,
      alt: audioRockfordFosgate.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '58.5%',
        yPos: '65%',
      },
      desktop: {
        xPos: '58.5%',
        yPos: '65%',
      },
    },
    details: ventilatorAirCleaner.details,
    zoomImage: {
      src: ventilatorAirCleaner.zoomImages.roadGlideSpecial,
      alt: ventilatorAirCleaner.details.title,
    },
  },
];

export const roadKingSpecialHotspots: Array<Hotspot> = [
  {
    coordinates: {
      mobile: {
        xPos: '70%',
        yPos: '78%',
      },
      desktop: {
        xPos: '70%',
        yPos: '78%',
      },
    },
    details: engineGuard.details,
    zoomImage: {
      src: engineGuard.zoomImages.roadKingSpecial,
      alt: engineGuard.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '83%',
        yPos: '35%',
      },
      desktop: {
        xPos: '83%',
        yPos: '35%',
      },
    },
    details: daymakerLedHeadlamp.details,
    zoomImage: {
      src: daymakerLedHeadlamp.zoomImages.roadKingSpecial,
      alt: daymakerLedHeadlamp.details.title,
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '59%',
        yPos: '40%',
      },
      desktop: {
        xPos: '59%',
        yPos: '40%',
      },
    },
    details: {
      title: 'New Tank Medallion',
      description:
        'NEW TANK MEDALLIONA new tank medallion based on the Bar & Shield logo comes on all 2021 Road King® Specials.',
    },
    zoomImage: {
      src: RoadKingSpecialNewTankMedallion,
      alt: 'New Tank Medallion',
    },
  },
  {
    coordinates: {
      mobile: {
        xPos: '2%',
        yPos: '63%',
      },
      desktop: {
        xPos: '2%',
        yPos: '63%',
      },
    },
    details: brakingLights.details,
    zoomImage: {
      src: brakingLights.zoomImages.all,
      alt: brakingLights.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '39%',
        yPos: '49%',
      },
      desktop: {
        xPos: '39%',
        yPos: '49%',
      },
    },
    details: hammockSeat.details,
    zoomImage: {
      src: hammockSeat.zoomImages.all,
      alt: hammockSeat.details.title,
    },
    isUpgrade: true,
  },
  {
    coordinates: {
      mobile: {
        xPos: '58.5%',
        yPos: '65%',
      },
      desktop: {
        xPos: '58.5%',
        yPos: '65%',
      },
    },
    details: ventilatorAirCleaner.details,
    zoomImage: {
      src: ventilatorAirCleaner.zoomImages.roadKingSpecial,
      alt: ventilatorAirCleaner.details.title,
    },
  },
];
