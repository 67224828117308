import { links } from '../data/enum/links';

export const findDealerByLocale = { current: '' };

export const setFindADealerByLocale = (url: string) => {
  findDealerByLocale.current = url;
};

export const getFindDealerLink = (locale: string) => {
  switch (locale) {
    case 'de_AT':
    case 'de_DE':
    case 'de_CH':
      return `${links.findDealer}/de_DE/EU2104603`;

    case 'en_AA':
    case 'nl_BE':
    case 'cs_CZ':
    case 'da_DK':
    case 'fi_FI':
    case 'en_GR':
    case 'hu_HU':
    case 'en_IE':
    case 'en_AC':
    case 'ar_AC':
    case 'nl_NL':
    case 'no_NO':
    case 'en_EU':
    case 'pl_PL':
    case 'sv_SE':
    case 'tr_TR':
    case 'en_GB':
      return `${links.findDealer}/en_GB/EU2104603`;

    case 'es_ES':
      return `${links.findDealer}/es_ES/EU2104603 `;

    case 'fr_BE':
    case 'fr_FR':
    case 'fr_LU':
    case 'fr_CH':
      return `${links.findDealer}/fr_FR/EU2104603`;

    case 'it_IT':
    case 'it_CH':
      return `${links.findDealer}/it_IT/EU2104603`;

    case 'pt_PT':
      return `${links.findDealer}/pt_PT/EU2104603`;

    case 'ru_RU':
      return `${links.findDealer}/ru_RU/EU2104603`;

    case 'en_AP':
    case 'en_AU':
    case 'en_IN':
    case 'en_NZ':
    case 'es_LA':
    case 'es_MX':
    case 'ja_JP':
    case 'ko_KR':
    case 'th_TH':
    case 'vi_VN':
    case 'zh_CN':
    case 'zh_TW':
      return `https://freedom.harley-davidson.com/${locale}-PanAmerica-RegisterInterest`;

    default:
      return links.contactYourDealer;
  }
};
