/* eslint-disable max-lines */
import type { Image, Sketch } from '../types/global';
import { cvoData } from './cvo';
import { touringData } from './touring';
import { Sections } from './enum/sections';
import { streetBob114Data } from './streetBob114';
import { fatBoy114Data } from './fatBoy114';

// Lance Coury
import couryAvatar from '../assets/images/rider-stories/lance-coury/avatar.jpg';
import couryPhoto1 from '../assets/images/rider-stories/lance-coury/lance-coury-photo-1.jpg';
import couryPhoto2 from '../assets/images/rider-stories/lance-coury/lance-coury-photo-2.jpg';
import couryLoop1 from '../assets/images/rider-stories/lance-coury/lance-coury-loop-1.mp4';
import couryLoopImage1 from '../assets/images/rider-stories/lance-coury/lance-coury-loop-1.jpg';
import couryLoop2 from '../assets/images/rider-stories/lance-coury/lance-coury-loop-2.mp4';
import couryLoopImage2 from '../assets/images/rider-stories/lance-coury/lance-coury-loop-2.jpg';

// Lance Coury Sketch Bike
import SketchRoadGlide from '../assets/images/CVO/road-glide/sketch-cvo-road-glide.png';
import SketchRoadGlideColored from '../assets/images/CVO/road-glide/cvo-road-glide-black-hole.png';

// Adey Bennett
import bennettAvatar from '../assets/images/rider-stories/adey-bennett/avatar.jpg';
import bennettPhoto1 from '../assets/images/rider-stories/adey-bennett/adey-bennett-photo-1.jpg';
import bennettPhoto2 from '../assets/images/rider-stories/adey-bennett/adey-bennett-photo-2.jpg';
import bennettLoop1 from '../assets/images/rider-stories/adey-bennett/adey-bennett-loop-1.mp4';
import bennettLoopImage1 from '../assets/images/rider-stories/adey-bennett/adey-bennett-loop-1.jpg';
import bennettLoop2 from '../assets/images/rider-stories/adey-bennett/adey-bennett-loop-2.mp4';
import bennettLoopImage2 from '../assets/images/rider-stories/adey-bennett/adey-bennett-loop-2.jpg';

// Ashmore Ellis
import ellisAvatar from '../assets/images/rider-stories/ashmore-ellis/avatar.jpg';
import ellisPhoto1 from '../assets/images/rider-stories/ashmore-ellis/ashmore-ellis-photo-1.jpg';
import ellisPhoto2 from '../assets/images/rider-stories/ashmore-ellis/ashmore-ellis-photo-2.jpg';
import ellisLoop1 from '../assets/images/rider-stories/ashmore-ellis/ashmore-ellis-loop-1.mp4';
import ellisLoopImage1 from '../assets/images/rider-stories/ashmore-ellis/ashmore-ellis-loop-1.jpg';
import ellisLoop2 from '../assets/images/rider-stories/ashmore-ellis/ashmore-ellis-loop-2.mp4';
import ellisLoopImage2 from '../assets/images/rider-stories/ashmore-ellis/ashmore-ellis-loop-2.jpg';

// Davide Berruto
import berrutoAvatar from '../assets/images/rider-stories/davide-berruto/avatar.jpg';
import berrutoPhoto1 from '../assets/images/rider-stories/davide-berruto/davide-berruto-photo-1.jpg';
import berrutoPhoto2 from '../assets/images/rider-stories/davide-berruto/davide-berruto-photo-2.jpg';
import berrutoLoop1 from '../assets/images/rider-stories/davide-berruto/davide-berruto-loop-1.mp4';
import berrutoLoopImage1 from '../assets/images/rider-stories/davide-berruto/davide-berruto-loop-1.jpg';
import berrutoLoop2 from '../assets/images/rider-stories/davide-berruto/davide-berruto-loop-2.mp4';
import berrutoLoopImage2 from '../assets/images/rider-stories/davide-berruto/davide-berruto-loop-2.jpg';

// Jason Momoa
import momoaAvatar from '../assets/images/rider-stories/jason-momoa/avatar.jpg';
import momoaPhoto1 from '../assets/images/rider-stories/jason-momoa/jason-momoa-photo-1.jpg';
import momoaPhoto2 from '../assets/images/rider-stories/jason-momoa/jason-momoa-photo-2.jpg';
import momoaLoop1 from '../assets/images/rider-stories/jason-momoa/jason-momoa-loop-1.mp4';
import momoaLoopImage1 from '../assets/images/rider-stories/jason-momoa/jason-momoa-loop-1.jpg';
import momoaLoop2 from '../assets/images/rider-stories/jason-momoa/jason-momoa-loop-2.mp4';
import momoaLoopImage2 from '../assets/images/rider-stories/jason-momoa/jason-momoa-loop-2.jpg';

// Momoa Sketch Bike
import SketchPanAmerica1250 from '../assets/images/pan-america-1250/sketch-panam-1250.png';
import SketchPanAmerica1250Colored from '../assets/images/pan-america-1250/hover-panam-1250.png';

interface Rider {
  name: string;
  avatar: Image;
  photo1: Image;
  photo2: Image;
  video1: {
    file: string;
    image: Image;
  };
  video2: {
    file: string;
    image: Image;
  };
  description: {
    style: string;
    copy: string;
    quote: string;
    motorcycle: {
      copy: string;
      sketch: Sketch;
      signature: string;
    };
    anchor: string;
    isExternalLink?: boolean;
  };
  hasMerch: boolean;
}

interface RiderStoriesData {
  title: string;
  eyebrow: string;
  riders: Array<Rider>;
}

export const riderStoriesData: RiderStoriesData = {
  title: 'Rider Stories',
  eyebrow: 'RIDER STORIES',
  riders: [
    {
      name: 'Lance Coury Sr.',
      hasMerch: true,
      avatar: {
        src: couryAvatar,
        alt: 'Lance Coury Sr.',
      },
      photo1: {
        src: couryPhoto1,
        alt: 'Lance Coury Sr.',
      },
      photo2: {
        src: couryPhoto2,
        alt: 'Lance Coury Sr.',
      },
      video1: {
        file: couryLoop1,
        image: {
          src: couryLoopImage1,
          alt: 'Lance Coury Sr.',
        },
      },
      video2: {
        file: couryLoop2,
        image: {
          src: couryLoopImage2,
          alt: 'Lance Coury Sr.',
        },
      },
      description: {
        style: 'The Highway Man',
        copy:
          'On his motorcycle adventures, Lance Coury criss-crosses the country on his Harley-Davidson, documenting big and small moments along the way. His YouTube channel 2LaneLife, inspires others to answer the call to adventure and a life on two wheels.',
        quote: 'If you have any sense of adventure in your heart and soul, get on the open road.',
        motorcycle: {
          copy: "Lance Coury Sr's Ride",
          sketch: {
            initial: {
              src: SketchRoadGlide,
              alt: '2021 CVO™ ROAD GLIDE®',
            },
            hover: {
              src: SketchRoadGlideColored,
              alt: '2021 CVO™ ROAD GLIDE®',
            },
          },
          signature: cvoData.bikes[1].signature,
        },
        anchor: Sections.CVO,
      },
    },
    {
      name: 'Adey Bennett',
      hasMerch: true,
      avatar: {
        src: bennettAvatar,
        alt: 'Adey Bennett',
      },
      photo1: {
        src: bennettPhoto1,
        alt: 'Adey Bennett',
      },
      photo2: {
        src: bennettPhoto2,
        alt: 'Adey Bennett',
      },
      video1: {
        file: bennettLoop1,
        image: {
          src: bennettLoopImage1,
          alt: 'Adey Bennett',
        },
      },
      video2: {
        file: bennettLoop2,
        image: {
          src: bennettLoopImage2,
          alt: 'Adey Bennett',
        },
      },
      description: {
        style: 'Adrenaline Junkie',
        copy:
          'With a focus on storytelling and high-octane content, Adey has become a unique and authentic voice in the motorcycle world. The LA based creative director has built a large following on both Instagram and YouTube where he shares his fast-paced lifestyle.',
        quote: 'When I’m out there being inspired, I’m creating a new story for myself.',
        motorcycle: {
          copy: "Adey Bennett's Ride",
          sketch: touringData.bikes[1].sketch,
          signature: touringData.bikes[1].signature,
        },
        anchor: Sections.SPECIALS,
      },
    },
    {
      name: 'Ashmore Ellis',
      hasMerch: true,
      avatar: {
        src: ellisAvatar,
        alt: 'Ashmore Ellis',
      },
      photo1: {
        src: ellisPhoto1,
        alt: 'Ashmore Ellis',
      },
      photo2: {
        src: ellisPhoto2,
        alt: 'Ashmore Ellis',
      },
      video1: {
        file: ellisLoop1,
        image: {
          src: ellisLoopImage1,
          alt: 'Ashmore Ellis',
        },
      },
      video2: {
        file: ellisLoop2,
        image: {
          src: ellisLoopImage2,
          alt: 'Ashmore Ellis',
        },
      },
      description: {
        style: 'The Trailblazer',
        copy:
          'As a mother, wife, entrepreneur and the founder of Babes Ride Out, Ashmore Ellis rides the line between many worlds. But since the birth of her daughter, riding has also become a way for Ashmore to reclaim her identity and recharge for her fulfilling, but busy everyday life.',
        quote: 'If you truly love to do something, you’ve got to go do it. No matter what.',
        motorcycle: {
          copy: "Ashmore Ellis' Ride",
          sketch: streetBob114Data.sketch,
          signature: streetBob114Data.signature,
        },

        anchor: Sections.STREET_BOB_114,
      },
    },
    {
      name: 'Davide Berruto',
      hasMerch: true,
      avatar: {
        src: berrutoAvatar,
        alt: 'Davide Berruto',
      },
      photo1: {
        src: berrutoPhoto1,
        alt: 'Davide Berruto',
      },
      photo2: {
        src: berrutoPhoto2,
        alt: 'Davide Berruto',
      },
      video1: {
        file: berrutoLoop1,
        image: {
          src: berrutoLoopImage1,
          alt: 'Davide Berruto',
        },
      },
      video2: {
        file: berrutoLoop2,
        image: {
          src: berrutoLoopImage2,
          alt: 'Davide Berruto',
        },
      },
      description: {
        style: 'The Originale',
        copy:
          'Originally from Milan, Davide’s unmistakable sense of style sets him apart in the motorcycling world. A purveyor of the finer things in life, this entrepreneur has painstakingly cultivated an authentic and adventurous life—or as the Italians might say, a ‘dolce vita’.',
        quote: 'It’s part of the American heritage, part of the American dream.',
        motorcycle: {
          copy: "Davide Berruto's Ride",
          sketch: fatBoy114Data.sketch,
          signature: fatBoy114Data.signature,
        },
        anchor: Sections.FAT_BOY_114,
      },
    },
    {
      name: 'Jason Momoa',
      hasMerch: false,
      avatar: {
        src: momoaAvatar,
        alt: 'Jason Momoa',
      },
      photo1: {
        src: momoaPhoto1,
        alt: 'Jason Momoa',
      },
      photo2: {
        src: momoaPhoto2,
        alt: 'Jason Momoa',
      },
      video1: {
        file: momoaLoop1,
        image: {
          src: momoaLoopImage1,
          alt: 'Jason Momoa',
        },
      },
      video2: {
        file: momoaLoop2,
        image: {
          src: momoaLoopImage2,
          alt: 'Jason Momoa',
        },
      },
      description: {
        style: 'Leader of the Pack',
        copy:
          'American actor, producer, director, and motorcycle enthusiast Jason Momoa is forever seeking new adventures. Join us February 22 as he debuts his new short film, and discusses how the all-new Pan America™ motorcycle has expanded his passion for Harley-Davidson and exploring endless horizons.',
        quote: 'PAN AMERICA combines my love of the outdoors, the unknown, and Harley-Davidson.',
        motorcycle: {
          copy: 'Join Us 2/22/20',
          sketch: {
            initial: {
              src: SketchPanAmerica1250,
              alt: 'Pan America 1250',
            },
            hover: {
              src: SketchPanAmerica1250Colored,
              alt: 'Pan America 1250',
            },
          },
          signature: 'Pan America 1250',
        },
        anchor:
          'https://www.harley-davidson.com/us/en/products/bikes/future-vehicles/pan-america.html',
        isExternalLink: true,
      },
    },
  ],
};
