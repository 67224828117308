export interface BikeColor {
  name: string;
  hex: Array<string>;
  isGradient?: boolean;
}

export const sunsetOrangeFade: BikeColor = {
  name: 'Sunset Orange Fade',
  hex: ['#C42A18', '#1D1D1D'],
};

export const greatWhitePearl: BikeColor = {
  name: 'Great White Pearl',
  hex: ['#DEDEDE'],
};

export const bronzeArmor: BikeColor = {
  name: 'Bronze Armor',
  hex: ['#827769'],
};

export const blackHole: BikeColor = {
  name: 'Black Hole',
  hex: ['#161616'],
};

export const charredCrimson: BikeColor = {
  name: 'Charred Crimson',
  hex: ['#4E1A1C'],
};

export const royalPurpleFade: BikeColor = {
  name: 'Royal Purple Fade',
  hex: ['#312442', '#1D1D1D'],
};

export const billiardRedVividBlack: BikeColor = {
  name: 'Billiard Red Vivid Black',
  hex: ['#810623', '#1D1D1D'],
};

export const billiardRed: BikeColor = {
  name: 'Billiard Red',
  hex: ['#810623'],
};

export const billiardTeal: BikeColor = {
  name: 'Billiard Teal',
  hex: ['#2F4C5A'],
};

export const deadwoodGreen: BikeColor = {
  name: 'Deadwood Green',
  hex: ['#4C4D3F'],
};

export const gauntletGrayVividBlack: BikeColor = {
  name: 'Gauntlet Gray Vivid Black',
  hex: ['#403F44', '#1D1D1D'],
};

export const blackJackMetallic: BikeColor = {
  name: 'Black Jack Metallic',
  hex: ['#555360'],
};

export const midnightCrimson: BikeColor = {
  name: 'Midnight Crimson',
  hex: ['#503232'],
};

export const snakeVenom: BikeColor = {
  name: 'Snake Venom',
  hex: ['#50326C', '#4A6150'],
  isGradient: true,
};

export const vividBlack: BikeColor = {
  name: 'Vivid Black',
  hex: ['#1D1D1D'],
};
