import type { Image, Bike, SeatInfo } from '../types/global';
import AudioSystemImage from '../assets/images/CVO/audio-system.jpg';
import PosterImage from '../assets/images/CVO/poster.jpg';
import seatZoomedImage from '../assets/images/CVO/seat/seat-zoomed-image.jpg';

import {
  blackHole,
  charredCrimson,
  greatWhitePearl,
  royalPurpleFade,
  sunsetOrangeFade,
  bronzeArmor,
} from './enum/bikeColors';

import {
  streetGlideHotspots,
  roadGlideHotspots,
  triGlideHotspots,
  limitedHotspots,
} from './hotspots/cvo';

// Street Glide
import StreetGlideBronzeArmor from '../assets/images/CVO/street-glide/cvo-street-glide-bronze-armor.png';
import StreetGlideWhitePearl from '../assets/images/CVO/street-glide/cvo-street-glide-white-pearl.png';
import StreetGlideSunsetOrange from '../assets/images/CVO/street-glide/cvo-street-glide-sunset-orange.png';
import SketchInvertedStreetGlide from '../assets/images/CVO/street-glide/sketch-inverted-cvo-street-glide.png';

// Road Glide
import RoadGlideBronzeArmor from '../assets/images/CVO/road-glide/cvo-road-glide-bronze-armor.png';
import RoadGlideSunsetOrange from '../assets/images/CVO/road-glide/cvo-road-glide-sunset-orange.png';
import RoadGlideBlackHole from '../assets/images/CVO/road-glide/cvo-road-glide-black-hole.png';
import SketchInvertedRoadGlide from '../assets/images/CVO/road-glide/sketch-inverted-cvo-road-glide.png';

// Tri Glide
import TriGlideCharredCrimson from '../assets/images/CVO/tri-glide/cvo-tri-glide-charred-crimson.png';
import TriGlideSunsetOrangeFade from '../assets/images/CVO/tri-glide/cvo-tri-glide-sunset-orange-fade.png';
import SketchInvertedTriGlide from '../assets/images/CVO/tri-glide/sketch-inverted-cvo-tri-glide.png';

// Limited
import LimitedBronzeArmor from '../assets/images/CVO/limited/cvo-limited-bronze-armor.png';
import LimitedRoyalPurpleFade from '../assets/images/CVO/limited/cvo-limited-royal-purple-fade.png';
import SketchInvertedLimited from '../assets/images/CVO/limited/sketch-inverted-cvo-limited.png';

interface CVOData {
  title: string;
  eyebrow: string;
  description: string;
  soundSection: {
    title: string;
    description: string;
    bikeImage: Image;
    audioSystemImage: Image;
  };
  seatSection: {
    zoomedImage: Image;
    seats: Array<SeatInfo>;
  };
  posterImage: Image;
  bikes: Array<Bike>;
}

export const cvoData: CVOData = {
  title: 'FORTUNE FAVORS THE BOLD',
  eyebrow: 'CVO',
  description:
    'Get ready to be blown away by a sonic storm. Harley-Davidson® Audio powered by Rockford Fosgate® is designed to be the new standard of motorcycle audio performance and customization, and now comes stock on CVO Road Glide and CVO Street Glide.',
  soundSection: {
    title: 'NEW LEGENDARY SOUND',
    description:
      'Harley-Davidson and Rockford Fosgate have worked together to create the highest performing, most advanced audio system riders have ever experienced. This is truly Legendary Sound from two legendary brands.',
    bikeImage: {
      src: SketchInvertedStreetGlide,
      alt: 'CVO Bike Sketch Image',
    },
    audioSystemImage: {
      src: AudioSystemImage,
      alt: 'Audio System Image',
    },
  },
  posterImage: {
    src: PosterImage,
    alt: 'Poster Image',
  },
  seatSection: {
    zoomedImage: {
      src: seatZoomedImage,
      alt: 'Seat Zoomed Image',
    },
    seats: [
      {
        button: 'Summer',
        title: 'Keep your cool',
        copy:
          'Through Harley-Davidson Parts and Accessories take advantage of those long summer days with our industry-leading seat cooling technology. Built into our premium seating for Touring and Trike models, our cooling seats will extend your riding season and help you stay cool, whatever the weather.',
      },
      {
        button: 'Winter',
        title: 'SIT DOWN, WARM UP',
        copy:
          'With the heating technology built into our premium seating for Touring and Trike models, your riding season just got longer. The improved heating function now reaches the selected temperature twice as fast as previous heated seats so you can ride comfortably any time of year. Available through Harley-Davidson Parts and Accessories.',
      },
    ],
  },
  bikes: [
    {
      name: '2021 CVO™ STREET GLIDE®',
      description:
        'The pinnacle of the custom touring look taken to the edge and loaded with power. No corners were cut, no shortcuts were taken. We sweat every detail and then polished it like a jewel. Built for riders seeking a show-stopping custom bagger.',
      sketch: {
        initial: {
          src: SketchInvertedStreetGlide,
          alt: '2021 CVO™ STREET GLIDE®',
        },
        hover: {
          src: StreetGlideWhitePearl,
          alt: '2021 CVO™ STREET GLIDE®',
        },
      },
      signature: 'CVO Street Glide',
      variants: [
        { bikeColor: bronzeArmor, bikeImage: StreetGlideBronzeArmor },
        { bikeColor: greatWhitePearl, bikeImage: StreetGlideWhitePearl },
        { bikeColor: sunsetOrangeFade, bikeImage: StreetGlideSunsetOrange },
      ],
      hotspots: streetGlideHotspots,
    },
    {
      name: '2021 CVO™ ROAD GLIDE®',
      description:
        'The 2021 CVO™ Road Glide® is designed to turn heads and drop jaws. This limited-edition machine is loaded with custom details, a Milwaukee-Eight® 117 Engine and Stage II Harley-Davidson® Audio powered by Rockford Fosgate.®',
      sketch: {
        initial: {
          src: SketchInvertedRoadGlide,
          alt: '2021 CVO™ ROAD GLIDE®',
        },
        hover: {
          src: RoadGlideBlackHole,
          alt: '2021 CVO™ ROAD GLIDE®',
        },
      },
      signature: 'CVO Road Glide',
      variants: [
        { bikeColor: bronzeArmor, bikeImage: RoadGlideBronzeArmor },
        { bikeColor: sunsetOrangeFade, bikeImage: RoadGlideSunsetOrange },
        { bikeColor: blackHole, bikeImage: RoadGlideBlackHole },
      ],
      hotspots: roadGlideHotspots,
    },
    {
      name: '2021 CVO™ TRI GLIDE®',
      description:
        'The all-new CVO™ Tri Glide® is a custom trike that’s locked-and-loaded with more premium touring features than you could ever imagine. Take on the miles ahead with bold styling, Twin-Cooled™ Milwaukee-Eight® 117 engine and Kahuna™ controls.',
      sketch: {
        initial: {
          src: SketchInvertedTriGlide,
          alt: '2021 CVO™ TRI GLIDE®',
        },
        hover: {
          src: TriGlideCharredCrimson,
          alt: '2021 CVO™ TRI GLIDE®',
        },
      },
      signature: 'CVO Tri Glide',
      variants: [
        { bikeColor: charredCrimson, bikeImage: TriGlideCharredCrimson },
        { bikeColor: sunsetOrangeFade, bikeImage: TriGlideSunsetOrangeFade },
      ],
      hotspots: triGlideHotspots,
    },
    {
      name: '2021 CVO™ LIMITED®',
      description:
        'The ultimate in Grand American Touring. Best-in-class power, show-stopping paint and premium comfort for you and your passenger. Sit back for a one-of-a-kind touring experience with bold styling through premium paint, Tomahawk™ wheels and Twin-Cooled™ Milwaukee-Eight® 117 engine.',
      sketch: {
        initial: {
          src: SketchInvertedLimited,
          alt: '2021 CVO™ LIMITED®',
        },
        hover: {
          src: LimitedRoyalPurpleFade,
          alt: '2021 CVO™ LIMITED®',
        },
      },
      signature: 'CVO Limited',
      variants: [
        { bikeColor: bronzeArmor, bikeImage: LimitedBronzeArmor },
        { bikeColor: royalPurpleFade, bikeImage: LimitedRoyalPurpleFade },
      ],
      hotspots: limitedHotspots,
    },
  ],
};
