/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-restricted-properties */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { DisposableManager } from 'seng-disposable-manager';
import { useHistory } from 'react-router-dom';
import setViewportCustomProperties from '../utils/setViewportCustomProperties';
import { Routes } from './pages/Routes';
import { getAnalyticsScript } from '../utils/analytics';
import useScript from '../hooks/useScript';
import { LOCALE_QUERYPARAM } from '../constants';

gsap.registerPlugin(ScrollTrigger);

const disposables = new DisposableManager();
disposables.add(setViewportCustomProperties());

export default function App(): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    const deleteLocaleParam = () => {
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.delete(LOCALE_QUERYPARAM);

      history.replace({
        search: urlParams.toString(),
      });
    };

    deleteLocaleParam();
  }, []);

  useScript(getAnalyticsScript());
  return <Routes />;
}
