import type { SiteLocale } from '../types/locales';

export const mapLocale = (locale: string): SiteLocale | 'en_EU' => {
  switch (locale) {
    case 'de_AT':
    case 'de_DE':
    case 'de_CH':
      return 'de_DE';

    case 'en_AU':
    case 'en_CA':
    case 'en_GU':
    case 'en_IN':
    case 'id_ID':
    case 'en_NZ':
    case 'en_AP':
    case 'zh_TW':
    case 'en_US':
    case 'vi_VN':
      return 'en_US';

    case 'en_AA':
    case 'nl_BE':
    case 'cs_CZ':
    case 'da_DK':
    case 'fi_FI':
    case 'en_GR':
    case 'hu_HU':
    case 'en_IE':
    case 'en_AC':
    case 'ar_AC':
    case 'nl_NL':
    case 'no_NO':
    case 'en_EU':
    case 'pl_PL':
    case 'sv_SE':
    case 'tr_TR':
    case 'en_GB':
      return 'en_EU';

    case 'es_ES':
      return 'es_ES';

    case 'es_MX':
    case 'es_LA':
      return 'es_LA';

    case 'fr_CA':
      return 'fr_CA';

    case 'fr_BE':
    case 'fr_FR':
    case 'fr_LU':
    case 'fr_CH':
      return 'fr_FR';

    case 'it_IT':
    case 'it_CH':
      return 'it_IT';

    case 'ja_JP':
      return 'ja_JP';

    case 'ko_KR':
      return 'ko_KR';

    case 'pt_BR':
    case 'pt_PT':
      return 'pt_PT';

    case 'ru_RU':
      return 'ru_RU';

    case 'th_TH':
      return 'th_TH';

    default:
      return 'en_US';
  }
};
