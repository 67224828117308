import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { GlobalStoreProvider } from './GlobalStore';
import GlobalStyle from './GlobalStyle';
import theme from './theme/theme';
import { initI18n } from './utils/initI18n';

/** SETUP */

configure({
  // restricts observable value updating only to actions
  enforceActions: 'observed',
});

/** INITIALIZE i18N */
// TODO: ADD SUSPENSE
initI18n().then(() => {
  /** RENDER */

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalStoreProvider>
            <>
              <GlobalStyle />
              <App />
            </>
          </GlobalStoreProvider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>,
    // eslint-disable-next-line unicorn/prefer-query-selector
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
